import React, { useState, useEffect, useRef } from 'react';
import './UserPolicy.css';
import { Footer, Header } from '../../components';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const sections = [
    { id: 'scope-and-acceptance-of-terms', title: 'Scope and Acceptance of Terms' },
    { id: 'user-accounts-and-profiles', title: 'User Accounts and Profiles' },
    { id: 'acceptable-use', title: 'Acceptable Use' },
    { id: 'user-content', title: 'User Content' },
    { id: 'facial-recognition', title: 'Facial Recognition' },
    { id: 'intellectual-property', title: 'Intellectual Property' },
    { id: 'disclaimer-of-warranties', title: 'Disclaimer of Warranties' },
    { id: 'limitation-of-liability', title: 'Limitation of Liability' },
    { id: 'indemnification', title: 'Indemnification' },
    { id: 'termination', title: 'Termination' },
    { id: 'governing-law-and-dispute-resolution', title: 'Governing Law and Dispute Resolution' },
    { id: 'changes-to-this-policy', title: 'Changes to this Policy' },
    { id: 'contact-us', title: 'Contact Us' },
];

const UserPolicy = () => {
    const [activeSection, setActiveSection] = useState(sections[0].id);
    const sectionRefs = useRef([]);

    useEffect(() => {
        const observerOptions = {
            threshold: 0.1,
        };

        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setActiveSection(entry.target.id);
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        sectionRefs.current.forEach((section) => {
            if (section) observer.observe(section);
        });

        return () => {
            if (sectionRefs.current) {
                sectionRefs.current.forEach((section) => {
                    if (section) observer.unobserve(section);
                });
            }
        };
    }, []);

    const handleClick = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
            setActiveSection(id);
        }
    };
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isMobileMode = searchParams.get('mode') === 'mobile';
    return (
        <div className="privacy-policy">
            {!isMobileMode && <Header />}
            <Helmet>
                <meta charSet="utf-8" />
                <title>User Policy | nSepia</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <div className="main-container">
                <div className="sidebar-container open">
                    <nav className="sidebar">
                        <ul>
                            {sections.map((section) => (
                                <li
                                    key={section.id}
                                    className={`nav-item ${activeSection === section.id ? 'active' : ''}`}
                                    onClick={() => handleClick(section.id)}
                                >
                                    {section.title}
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
                <main className="content">
                    <h1>User Policy</h1>
                    <p>Effective Date: 1st July 2024</p>
                    {sections.map((section, index) => (
                        <section
                            key={section.id}
                            id={section.id}
                            className={`section ${activeSection === section.id ? 'active-section' : ''}`}
                            ref={(el) => (sectionRefs.current[index] = el)}
                        >
                            {section.id === 'scope-and-acceptance-of-terms' && (
                                <>
                                    <h2>1. Scope and Acceptance of Terms</h2>
                                    <p>This User Policy ("Policy") governs your use of the nSepia mobile application ("App"). By accessing or using the App, you agree to comply with this Policy and our Privacy Policy, which is incorporated herein by reference. If you do not agree to these terms, you must refrain from using the App.</p>
                                </>
                            )}
                            {section.id === 'user-accounts-and-profiles' && (
                                <>
                                    <h2>2. User Accounts and Profiles</h2>
                                    <p><strong>Registration:</strong> To access certain features of the App, you may need to create a user account. You agree to provide accurate, current, and complete information during registration and to keep your account information updated.</p>
                                    <p><strong>Account Security:</strong> You are solely responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. Notify us immediately of any unauthorized use of your account or any other security breach.</p>
                                    <p><strong>Multiple Profiles:</strong> If the App allows for multiple profiles on a single device, you are responsible for managing and ensuring the privacy of each profile.</p>
                                </>
                            )}
                            {section.id === 'acceptable-use' && (
                                <>
                                    <h2>3. Acceptable Use</h2>
                                    <p><strong>Purpose:</strong> The App is intended for personal, non-commercial use. You agree to use the App solely for its intended purpose, which is to assess anxiety levels using the GAD-7 questionnaire and receive personalized feedback.</p>
                                    <p><strong>Prohibited Activities:</strong> You may not use the App for any illegal, harmful, or unauthorized purposes. This includes, but is not limited to:</p>
                                    <ul>
                                        <li>Violating any applicable laws or regulations.</li>
                                        <li>Infringing upon the intellectual property rights of others.</li>
                                        <li>Transmitting any viruses, malware, or harmful code.</li>
                                        <li>Engaging in any activity that disrupts or interferes with the App's functionality or security.</li>
                                        <li>Attempting to gain unauthorized access to the App's systems or data.</li>
                                    </ul>
                                </>
                            )}
                            {section.id === 'user-content' && (
                                <>
                                    <h2>4. User Content</h2>
                                    <p><strong>Responsibility:</strong> You are solely responsible for any content you submit, post, or share through the App (e.g., comments, feedback). You represent and warrant that you have all necessary rights to such content and that it does not violate any third-party rights.</p>
                                    <p><strong>License:</strong> By submitting content, you grant us a worldwide, non-exclusive, royalty-free, perpetual, irrevocable, and sublicensable right to use, reproduce, modify, adapt, publish, translate, distribute, and display such content in any media.</p>
                                </>
                            )}
                            {section.id === 'facial-recognition' && (
                                <>
                                    <h2>5. Facial Recognition (Optional)</h2>
                                    <p><strong>Consent:</strong> If you choose to use the face recognition feature, you expressly consent to the collection and processing of your anonymized facial landmark data as described in our Privacy Policy.</p>
                                    <p><strong>Limitations:</strong> We make no representations or warranties regarding the accuracy or reliability of the face recognition feature.</p>
                                </>
                            )}
                            {section.id === 'intellectual-property' && (
                                <>
                                    <h2>6. Intellectual Property</h2>
                                    <p>The App, including all content, features, and functionality, is owned by Nihilent Ltd. or its licensors and is protected by copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
                                </>
                            )}
                            {section.id === 'disclaimer-of-warranties' && (
                                <>
                                    <h2>7. Disclaimer of Warranties</h2>
                                    <p>The App is provided on an "as is" and "as available" basis without any warranties of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not warrant that the App will be uninterrupted, error-free, or secure.</p>
                                </>
                            )}
                            {section.id === 'limitation-of-liability' && (
                                <>
                                    <h2>8. Limitation of Liability</h2>
                                    <p>To the fullest extent permitted by applicable law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use of or inability to use the App; (ii) any unauthorized access to or use of our servers and/or any and all personal information stored therein; (iii) any interruption or cessation of transmission to or from the App; (iv) any bugs, viruses, trojan horses, or the like which may be transmitted to or through the App by any third party; or (v) any errors or omissions in any content or for any loss or damage of any kind incurred as a result of your use of any content posted, transmitted, or otherwise made available via the App.</p>
                                </>
                            )}
                            {section.id === 'indemnification' && (
                                <>
                                    <h2>9. Indemnification</h2>
                                    <p>You agree to indemnify and hold us harmless from and against any and all claims, liabilities, damages (actual and consequential), losses, and expenses (including attorneys' fees) arising from or in any way related to your use of the App or your violation of these Terms.</p>
                                </>
                            )}
                            {section.id === 'termination' && (
                                <>
                                    <h2>10. Termination</h2>
                                    <p>We may terminate or suspend your access to the App immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms.</p>
                                </>
                            )}
                            {section.id === 'governing-law-and-dispute-resolution' && (
                                <>
                                    <h2>11. Governing Law and Dispute Resolution</h2>
                                    <p>These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions. Any dispute arising out of or relating to these Terms or the App shall be resolved exclusively by the state or federal courts located in [Your Jurisdiction].</p>
                                </>
                            )}
                            {section.id === 'changes-to-this-policy' && (
                                <>
                                    <h2>12. Changes to this Policy</h2>
                                    <p>We reserve the right to modify this User Policy at any time. If we make material changes, we will notify you through the App or by other means. Your continued use of the App after any modifications to the Policy constitutes your acceptance of the modified terms.</p>
                                </>
                            )}
                            {section.id === 'contact-us' && (
                                <>
                                    <h2>13. Contact Us</h2>
                                    <p>If you have any questions about this Policy, please contact us at <a href='mailto:support@nsepia.ai'>support@nsepia.ai</a>.</p>
                                </>
                            )}
                        </section>
                    ))}
                </main>
            </div>
            {!isMobileMode && <Footer />}
        </div>
    );
};

export default UserPolicy;
