import React from 'react';
import './home.css';
import heroImage from '../../assets/background_desktop.webp'; // Update the path to your hero background image

const Home = () => {
    return (
        <>
        <div className="hero-section" style={{
    backgroundImage: `url(${heroImage})`,
    filter: 'sepia(100%)', // Apply sepia filter
    backgroundSize: 'cover', // Ensure the background image covers the section
    backgroundPosition: 'center', // Center the image
  }}>
        {/* <div className="hero-section" style={{ backgroundColor: "#704214" }}> */}
                <div className="hero-content">
                    <div className="text-container">
                    <h1 className="cta" >Coming soon...</h1>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;
