import React, { useState, useEffect, useRef } from 'react';
import './Termsandcondition.css';
import { Footer, Header } from '../../components';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const sections = [
    { id: 'acceptance-of-terms', title: 'Acceptance of Terms' },
    { id: 'eligibility', title: 'Eligibility' },
    { id: 'app-use', title: 'App Use' },
    { id: 'intellectual-property', title: 'Intellectual Property' },
    { id: 'user-content', title: 'User Content' },
    { id: 'facial-recognition', title: 'Facial Recognition' },
    { id: 'privacy', title: 'Privacy' },
    { id: 'disclaimers', title: 'Disclaimers' },
    { id: 'limitation-of-liability', title: 'Limitation of Liability' },
    { id: 'indemnification', title: 'Indemnification' },
    { id: 'modifications-to-the-app', title: 'Modifications to the App' },
    { id: 'governing-law-and-jurisdiction', title: 'Governing Law and Jurisdiction' },
    { id: 'severability', title: 'Severability' },
    { id: 'entire-agreement', title: 'Entire Agreement' },
    { id: 'contact-us', title: 'Contact Us' },
];

const TermsAndConditions = () => {
    const [activeSection, setActiveSection] = useState(sections[0].id);
    const sectionRefs = useRef([]);

    useEffect(() => {
        const observerOptions = {
            threshold: 0.1,
        };

        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setActiveSection(entry.target.id);
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        sectionRefs.current.forEach((section) => {
            if (section) observer.observe(section);
        });

        return () => {
            if (sectionRefs.current) {
                sectionRefs.current.forEach((section) => {
                    if (section) observer.unobserve(section);
                });
            }
        };
    }, []);

    const handleClick = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
            setActiveSection(id);
        }
    };

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isMobileMode = searchParams.get('mode') === 'mobile';

    return (
        <div className="privacy-policy">
            {!isMobileMode && <Header />}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Terms And Conditions | nSepia</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <div className="main-container">
                <div className="sidebar-container open">
                    <nav className="sidebar">
                        <ul>
                            {sections.map((section) => (
                                <li
                                    key={section.id}
                                    className={`nav-item ${activeSection === section.id ? 'active' : ''}`}
                                    onClick={() => handleClick(section.id)}
                                >
                                    {section.title}
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
                <main className="content">
                    <h1>Terms & Conditions</h1>
                    <p>Effective Date: 1st July 2024</p>
                    {sections.map((section, index) => (
                        <section
                            key={section.id}
                            id={section.id}
                            className={`section ${activeSection === section.id ? 'active-section' : ''}`}
                            ref={(el) => (sectionRefs.current[index] = el)}
                        >
                            {section.id === 'acceptance-of-terms' && (
                                <>
                                    <h2>1. Acceptance of Terms</h2>
                                    <p>By downloading, accessing, or using the nSepia mobile application (the "App"), you acknowledge that you have read, understood, and agree to be bound by these Terms & Conditions ("Terms"), along with our Privacy Policy, which is incorporated herein by reference. If you do not agree to these Terms, you may not use the App.</p>
                                </>
                            )}
                            {section.id === 'eligibility' && (
                                <>
                                    <h2>2. Eligibility</h2>
                                    <p>You affirm that you are at least 13 years old or have reached the age of majority in your jurisdiction to use this App. If you are under 13, you may only use the App with the involvement and consent of a parent or legal guardian.</p>
                                </>
                            )}
                            {section.id === 'app-use' && (
                                <>
                                    <h2>3. App Use</h2>
                                    <p><strong>License Grant:</strong> We grant you a limited, non-exclusive, non-transferable, revocable license to use the App solely for your personal, non-commercial use and in accordance with these Terms.</p>
                                    <p><strong>Restrictions:</strong> You may not:</p>
                                    <ul>
                                        <li>Modify, adapt, translate, reverse engineer, decompile, disassemble, or create derivative works based on the App.</li>
                                        <li>Remove any copyright, trademark, or other proprietary notices from the App.</li>
                                        <li>Use the App for any illegal or unauthorized purpose.</li>
                                        <li>Interfere with or disrupt the integrity or performance of the App or its data.</li>
                                    </ul>
                                </>
                            )}
                            {section.id === 'intellectual-property' && (
                                <>
                                    <h2>4. Intellectual Property</h2>
                                    <p>All intellectual property rights in the App, including but not limited to copyrights, trademarks, patents, and trade secrets, are owned by or licensed to Nihilent Ltd. These Terms do not grant you any rights to use such intellectual property except as expressly authorized herein.</p>
                                </>
                            )}
                            {section.id === 'user-content' && (
                                <>
                                    <h2>5. User Content</h2>
                                    <p>You may have the opportunity to submit or post content through the App (e.g., comments, feedback). You retain ownership of any intellectual property rights you hold in that content. However, by submitting content, you grant us a non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media.</p>
                                </>
                            )}
                            {section.id === 'facial-recognition' && (
                                <>
                                    <h2>6. Facial Recognition</h2>
                                    <p><strong>Consent:</strong> If you choose to use the face recognition feature, you expressly consent to the collection and processing of your anonymized facial landmark data as described in our Privacy Policy. This data is used solely for authentication purposes and will not be shared with third parties without your consent.</p>
                                    <p><strong>Limitations:</strong> Face recognition technology is not foolproof. We do not guarantee its accuracy or reliability. You may opt-out of using face recognition and choose alternative authentication methods (if available).</p>
                                </>
                            )}
                            {section.id === 'privacy' && (
                                <>
                                    <h2>7. Privacy</h2>
                                    <p>Your privacy is important to us. Please refer to our Privacy Policy for information on how we collect, use, and disclose your information.</p>
                                </>
                            )}
                            {section.id === 'disclaimers' && (
                                <>
                                    <h2>8. Disclaimers</h2>
                                    <p><strong>No Medical Advice:</strong> The App is not intended to provide medical advice, diagnosis, or treatment. Always seek the advice of a qualified healthcare provider with any questions you may have regarding your mental health.</p>
                                    <p><strong>No Warranty:</strong> The App is provided on an "as is" and "as available" basis without any warranties of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
                                    <p><strong>Accuracy:</strong> We strive to provide accurate and up-to-date information through the App, but we do not guarantee the accuracy, completeness, or reliability of any content.</p>
                                </>
                            )}
                            {section.id === 'limitation-of-liability' && (
                                <>
                                    <h2>9. Limitation of Liability</h2>
                                    <p>To the fullest extent permitted by applicable law, we shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use of or inability to use the App; (ii) any unauthorized access to or use of our servers and/or any and all personal information stored therein; (iii) any interruption or cessation of transmission to or from the App; (iv) any bugs, viruses, trojan horses, or the like which may be transmitted to or through the App by any third party; or (v) any errors or omissions in any content or for any loss or damage of any kind incurred as a result of your use of any content posted, transmitted, or otherwise made available via the App.</p>
                                </>
                            )}
                            {section.id === 'indemnification' && (
                                <>
                                    <h2>10. Indemnification</h2>
                                    <p>You agree to indemnify and hold Nihilent Ltd., its affiliates, officers, directors, employees, agents, and licensors harmless from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your access to or use of the App, your violation of these Terms, or your violation of any applicable laws or regulations.</p>
                                </>
                            )}
                            {section.id === 'modifications-to-the-app' && (
                                <>
                                    <h2>11. Modifications to the App</h2>
                                    <p>We reserve the right to modify, suspend, or discontinue the App (or any part thereof) at any time with or without notice. We will not be liable to you or any third party for any modification, suspension, or discontinuation of the App.</p>
                                </>
                            )}
                            {section.id === 'governing-law-and-jurisdiction' && (
                                <>
                                    <h2>12. Governing Law and Jurisdiction</h2>
                                    <p>These Terms shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law provisions. Any dispute arising out of or relating to these Terms or the App shall be subject to the exclusive jurisdiction of the courts located in Pune, Maharashtra, India.</p>
                                </>
                            )}
                            {section.id === 'severability' && (
                                <>
                                    <h2>13. Severability</h2>
                                    <p>If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck from these Terms and the remaining provisions shall remain in full force and effect.</p>
                                </>
                            )}
                            {section.id === 'entire-agreement' && (
                                <>
                                    <h2>14. Entire Agreement</h2>
                                    <p>These Terms, together with the Privacy Policy, constitute the entire agreement between you and Nihilent Ltd. regarding your use of the App.</p>
                                </>
                            )}
                            {section.id === 'contact-us' && (
                                <>
                                    <h2>15. Contact Us</h2>
                                    <p>If you have any questions about these Terms, please contact us at <a href='mailto:support@nsepia.ai'>support@nsepia.ai</a>.</p>
                                </>
                            )}
                        </section>
                    ))}
                </main>
            </div>
            {!isMobileMode && <Footer />}
        </div>
    );
};

export default TermsAndConditions;
