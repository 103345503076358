import React, { useState } from 'react';
import { Header, Footer } from "../../components";
import './faq.css';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Faq = () => {
    const [expanded, setExpanded] = useState(null);

    const handleToggle = (panel) => {
        setExpanded(expanded === panel ? null : panel);
    };
    
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isMobileMode = searchParams.get('mode') === 'mobile';

    return (
        <>
             {!isMobileMode && <Header />}
             <Helmet>
                <meta charSet="utf-8" />
                <title>FAQ | nSepia</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <div className="faq-container">
                <h1>Frequently Asked Questions (FAQ)</h1>

                <section>
                    <h2>About the App:</h2>
                    <div className="faq-panel">
                        <div className="faq-summary" onClick={() => handleToggle('panel1')}>
                            <h3>What is nSepia?</h3>
                            <span className="arrow">{expanded === 'panel1' ? '▲' : '▼'}</span>
                        </div>
                        <div className={`faq-details ${expanded === 'panel1' ? 'expanded' : ''}`}>
                            <p>nSepia is a mobile app designed to help you self-assess your anxiety levels using the Generalized Anxiety Disorder 7-item (GAD-7) scale, a widely used and validated screening tool.</p>
                        </div>
                    </div>

                    <div className="faq-panel">
                        <div className="faq-summary" onClick={() => handleToggle('panel2')}>
                            <h3>How does it work?</h3>
                            <span className="arrow">{expanded === 'panel2' ? '▲' : '▼'}</span>
                        </div>
                        <div className={`faq-details ${expanded === 'panel2' ? 'expanded' : ''}`}>
                            <p>The app guides you through a conversational assessment with a friendly virtual assistant (chatbot). You'll be asked the seven questions of the GAD-7 scale, and you can respond using either voice input or by selecting from multiple-choice options.</p>
                        </div>
                    </div>

                    <div className="faq-panel">
                        <div className="faq-summary" onClick={() => handleToggle('panel3')}>
                            <h3>What does the GAD-7 measure?</h3>
                            <span className="arrow">{expanded === 'panel3' ? '▲' : '▼'}</span>
                        </div>
                        <div className={`faq-details ${expanded === 'panel3' ? 'expanded' : ''}`}>
                            <p>The GAD-7 measures the severity of generalized anxiety disorder (GAD) symptoms over the past two weeks. These symptoms include nervousness, restlessness, fatigue, difficulty concentrating, irritability, muscle tension, and sleep disturbances.</p>
                        </div>
                    </div>

                    <div className="faq-panel">
                        <div className="faq-summary" onClick={() => handleToggle('panel4')}>
                            <h3>Why should I use this app?</h3>
                            <span className="arrow">{expanded === 'panel4' ? '▲' : '▼'}</span>
                        </div>
                        <div className={`faq-details ${expanded === 'panel4' ? 'expanded' : ''}`}>
                            <p>The app offers a convenient, private, and accessible way to gain insights into your anxiety levels. It can be a helpful first step in understanding your mental well-being and seeking appropriate support if needed.</p>
                        </div>
                    </div>

                    <div className="faq-panel">
                        <div className="faq-summary" onClick={() => handleToggle('panel5')}>
                            <h3>Is the app a substitute for professional help?</h3>
                            <span className="arrow">{expanded === 'panel5' ? '▲' : '▼'}</span>
                        </div>
                        <div className={`faq-details ${expanded === 'panel5' ? 'expanded' : ''}`}>
                            <p>No. The app is not a replacement for professional medical advice, diagnosis, or treatment. It's designed for informational and self-assessment purposes only. If you are concerned about your anxiety, please consult a qualified healthcare provider.</p>
                        </div>
                    </div>

                    <div className="faq-panel">
                        <div className="faq-summary" onClick={() => handleToggle('panel6')}>
                            <h3>Who can use the app?</h3>
                            <span className="arrow">{expanded === 'panel6' ? '▲' : '▼'}</span>
                        </div>
                        <div className={`faq-details ${expanded === 'panel6' ? 'expanded' : ''}`}>
                            <p>The app is intended for adults experiencing anxiety symptoms. If you are under 13, please use the app with the guidance of a parent or guardian.</p>
                        </div>
                    </div>

                    <div className="faq-panel">
                        <div className="faq-summary" onClick={() => handleToggle('panel7')}>
                            <h3>How often should I take the assessment?</h3>
                            <span className="arrow">{expanded === 'panel7' ? '▲' : '▼'}</span>
                        </div>
                        <div className={`faq-details ${expanded === 'panel7' ? 'expanded' : ''}`}>
                            <p>You can take the assessment as often as you like. Some people find it helpful to track their anxiety levels over time, while others use it periodically to check in on their well-being.</p>
                        </div>
                    </div>
                </section>

                <section>
                    <h2>Accuracy and Results:</h2>
                    <div className="faq-panel">
                        <div className="faq-summary" onClick={() => handleToggle('panel8')}>
                            <h3>How accurate are the results?</h3>
                            <span className="arrow">{expanded === 'panel8' ? '▲' : '▼'}</span>
                        </div>
                        <div className={`faq-details ${expanded === 'panel8' ? 'expanded' : ''}`}>
                            <p>The GAD-7 is a reliable and valid screening tool, but it's not a diagnostic tool. The app provides a score based on your responses, but only a qualified healthcare professional can provide a diagnosis of GAD.</p>
                        </div>
                    </div>

                    <div className="faq-panel">
                        <div className="faq-summary" onClick={() => handleToggle('panel9')}>
                            <h3>What does my GAD-7 score mean?</h3>
                            <span className="arrow">{expanded === 'panel9' ? '▲' : '▼'}</span>
                        </div>
                        <div className={`faq-details ${expanded === 'panel9' ? 'expanded' : ''}`}>
                            <p>Your GAD-7 score reflects the severity of your anxiety symptoms. A higher score indicates more severe symptoms. The app will provide an interpretation of your score and suggest next steps.</p>
                        </div>
                    </div>

                    <div className="faq-panel">
                        <div className="faq-summary" onClick={() => handleToggle('panel10')}>
                            <h3>Can the app provide a diagnosis?</h3>
                            <span className="arrow">{expanded === 'panel10' ? '▲' : '▼'}</span>
                        </div>
                        <div className={`faq-details ${expanded === 'panel10' ? 'expanded' : ''}`}>
                            <p>No, the app cannot diagnose GAD. It's a screening tool designed to help you understand your symptoms and seek appropriate help.</p>
                        </div>
                    </div>
                </section>

                <section>
                    <h2>Privacy and Data:</h2>
                    <div className="faq-panel">
                        <div className="faq-summary" onClick={() => handleToggle('panel11')}>
                            <h3>How is my data used?</h3>
                            <span className="arrow">{expanded === 'panel11' ? '▲' : '▼'}</span>
                        </div>
                        <div className={`faq-details ${expanded === 'panel11' ? 'expanded' : ''}`}>
                            <p>Your GAD-7 responses are used solely to calculate your score and provide personalized feedback. We do not share your individual responses with any third parties. We may use anonymized and aggregated data for research and improvement purposes.</p>
                        </div>
                    </div>

                    <div className="faq-panel">
                        <div className="faq-summary" onClick={() => handleToggle('panel12')}>
                            <h3>What about my privacy?</h3>
                            <span className="arrow">{expanded === 'panel12' ? '▲' : '▼'}</span>
                        </div>
                        <div className={`faq-details ${expanded === 'panel12' ? 'expanded' : ''}`}>
                            <p>We take your privacy very seriously. Our Privacy Policy details our practices regarding data collection, use, and protection. We are committed to complying with relevant privacy laws and regulations like GDPR and HIPAA.</p>
                        </div>
                    </div>

                    <div className="faq-panel">
                        <div className="faq-summary" onClick={() => handleToggle('panel13')}>
                            <h3>Can I delete my data?</h3>
                            <span className="arrow">{expanded === 'panel13' ? '▲' : '▼'}</span>
                        </div>
                        <div className={`faq-details ${expanded === 'panel13' ? 'expanded' : ''}`}>
                            <p>Yes, you can delete your stored data at any time through the app settings.</p>
                        </div>
                    </div>
                </section>

                <section>
                    <h2>Face Recognition:</h2>
                    <div className="faq-panel">
                        <div className="faq-summary" onClick={() => handleToggle('panel14')}>
                            <h3>Why do you use face recognition?</h3>
                            <span className="arrow">{expanded === 'panel14' ? '▲' : '▼'}</span>
                        </div>
                        <div className={`faq-details ${expanded === 'panel14' ? 'expanded' : ''}`}>
                            <p>Face recognition is an optional feature to enhance security and privacy, especially if you share your device with others. It helps ensure that only you can access your assessment results.</p>
                        </div>
                    </div>

                    <div className="faq-panel">
                        <div className="faq-summary" onClick={() => handleToggle('panel15')}>
                            <h3>Is it safe?</h3>
                            <span className="arrow">{expanded === 'panel15' ? '▲' : '▼'}</span>
                        </div>
                        <div className={`faq-details ${expanded === 'panel15' ? 'expanded' : ''}`}>
                            <p>Yes, we take stringent security measures to protect your facial data. We do not store your raw facial images; instead, we use anonymized mathematical representations for authentication purposes only.</p>
                        </div>
                    </div>

                    <div className="faq-panel">
                        <div className="faq-summary" onClick={() => handleToggle('panel16')}>
                            <h3>Can I disable face recognition?</h3>
                            <span className="arrow">{expanded === 'panel16' ? '▲' : '▼'}</span>
                        </div>
                        <div className={`faq-details ${expanded === 'panel16' ? 'expanded' : ''}`}>
                            <p>Absolutely! You can enable or disable this feature in the app settings.</p>
                        </div>
                    </div>
                </section>

                <section>
                    <h2>Technical Issues and Support:</h2>
                    <div className="faq-panel">
                        <div className="faq-summary" onClick={() => handleToggle('panel17')}>
                            <h3>Who can I contact for help?</h3>
                            <span className="arrow">{expanded === 'panel17' ? '▲' : '▼'}</span>
                        </div>
                        <div className={`faq-details ${expanded === 'panel17' ? 'expanded' : ''}`}>
                            <p>If you have any questions, concerns, or technical issues, please reach out to our support team at <a href='mailto:support@nsepia.ai'>support@nsepia.ai</a>. We are here to help.</p>
                        </div>
                    </div>

                    <div className="faq-panel">
                        <div className="faq-summary" onClick={() => handleToggle('panel18')}>
                            <h3>What if the app crashes or doesn't work properly?</h3>
                            <span className="arrow">{expanded === 'panel18' ? '▲' : '▼'}</span>
                        </div>
                        <div className={`faq-details ${expanded === 'panel18' ? 'expanded' : ''}`}>
                            <p>Try restarting the app or your device. If the problem persists, please contact our support team with details of the issue.</p>
                        </div>
                    </div>
                </section>

                <section>
                    <h2>Disclaimer:</h2>
                    <div className="faq-panel">
                        <div className="faq-summary" onClick={() => handleToggle('panel19')}>
                            <h3>Disclaimer</h3>
                            <span className="arrow">{expanded === 'panel19' ? '▲' : '▼'}</span>
                        </div>
                        <div className={`faq-details ${expanded === 'panel19' ? 'expanded' : ''}`}>
                            <p>The information provided by this app is not intended as a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of a qualified healthcare provider with any questions you may have regarding your mental health.</p>
                        </div>
                    </div>
                </section>
            </div>
            {!isMobileMode && <Footer />}
        </>
    );
}

export default Faq;
