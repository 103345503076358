import React from 'react';
import { Header, Footer } from "../../components";
import './contactus.css';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const ContactUs = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isMobileMode = searchParams.get('mode') === 'mobile';
    return (
        <>
            {!isMobileMode && <Header />}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact Us | nSepia</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <div className="contact-container">
                <h1>Contact Us</h1>
                <div className="contact-card">
                    <p>If you need any help, we are here to assist you. Please email us at <a href='mailto:support@nsepia.ai'>support@nsepia.ai</a></p>
                </div>
            </div>
            {!isMobileMode && <Footer />}
        </>
    );
}

export default ContactUs;
