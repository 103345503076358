import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="header">
            <div className="logo">
                <Link to="/">nSepia</Link>
            </div>
            <div className="hamburger" onClick={toggleMenu}>
                {isMenuOpen ? '✕' : '☰'}
            </div>
            <nav className={`nav-buttons ${isMenuOpen ? 'open' : ''}`}>
                {/* <Link to="/" className="nav-button">Home</Link>
                <Link to="/faq" className="nav-button">FAQ</Link>
                <Link to="/TermsAndCondition" className="nav-button">T&C</Link>
                <Link to="/privacy-policy" className="nav-button">Privacy Policy</Link>
                <Link to="/user-policy" className="nav-button">User Policy</Link>
                <Link to="/about-us" className="nav-button">About Us</Link> */}
                <a href="/contact-us" className="nav-button">Contact Us</a>
            </nav>
        </header>
    );
}

export default Header;
