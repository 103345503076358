import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-section about">
                <h2>About Us</h2>
                <p>We are committed to providing the best services and products. Our team is dedicated to excellence and customer satisfaction.</p>
            </div>
            <div className="footer-section contact">
                <h2>Contact Us</h2>
                <p>Email: <a href='mailto:support@nsepia.ai'>support@nsepia.ai</a></p>
            </div>
            <div className="footer-section follow">
                <h2>Follow Us</h2>
                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a> <br/>
                <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a> <br/>
                <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a> <br/>
                <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">LinkedIn</a> <br/>
            </div>
            <div className="footer-section links">
                <h2>Useful Links</h2>
                <a href="/">Home</a><br/>
                <a href="/faq">FAQ</a><br/>
                <a href="/TermsAndCondition">T&C</a> <br/>
                <a href="/privacy-policy">Privacy Policy</a><br/>
                <a href="/user-policy">User Policy</a><br/>
                <a href="/about-us">About Us</a><br/>
                <a href="/contact-us">Contact Us</a><br/>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 Nihilent Ltd. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
