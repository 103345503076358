import React from 'react';
import { useLocation } from 'react-router-dom';
import { Header, Footer } from "../../components";
import './aboutus.css';
import { Helmet } from 'react-helmet';

const AboutUs = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isMobileMode = searchParams.get('mode') === 'mobile';

    return (
        <>
            {!isMobileMode && <Header />}
            <Helmet>
                <meta charSet="utf-8" />
                <title>About Us | nSepia</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <div className="about-container">
                <h1>About nSepia</h1>
                <div className="about-card">
                    <h2>Our Mission:</h2>
                    <p>At nSepia, we believe that everyone deserves access to tools and resources that empower them to understand and manage their mental health. We've created a user-friendly mobile app that leverages the clinically validated GAD-7 assessment tool to help individuals gain insights into their anxiety levels and take proactive steps towards well-being.</p>

                    <h2>Our Story:</h2>
                    <p>nSepia was born out of a shared passion for mental health advocacy and a deep understanding of the challenges faced by those struggling with anxiety. Our team of [mention relevant expertise, e.g., mental health professionals, app developers, designers] came together with a vision to harness the power of technology to make mental health support more accessible, affordable, and stigma-free.</p>

                    <h2>Our Approach:</h2>
                    <p>We are committed to providing a safe, private, and supportive space for users to explore their mental health. We utilize cutting-edge technology, including artificial intelligence and machine learning, to deliver personalized feedback and recommendations based on your individual needs and preferences.</p>

                    <h2>Our Team:</h2>
                    <p>Our team consists of dedicated individuals with expertise in [list relevant areas of expertise, e.g., psychology, psychiatry, software development, user experience design]. We are united by a shared passion for improving mental health outcomes and making a positive impact in the lives of our users.</p>

                    <h2>Our Commitment to You:</h2>
                    <ul>
                        <li><strong>Privacy:</strong> We prioritize your privacy and security. Your data is handled with the utmost care and in compliance with all applicable data protection regulations.</li>
                        <li><strong>Scientific Rigor:</strong> We base our assessment tools and recommendations on established clinical research and best practices in mental health.</li>
                        <li><strong>Continuous Improvement:</strong> We are constantly striving to enhance the app's features, functionality, and user experience based on your feedback.</li>
                    </ul>

                    <h2>Our Vision:</h2>
                    <p>We envision a world where everyone feels empowered to take charge of their mental well-being. We believe that nSepia can be a valuable tool in your journey towards better mental health.</p>

                    <h2>Join Us on This Journey:</h2>
                    <p>Download nSepia today and take the first step towards a healthier, happier you.</p>
                </div>
            </div>
            {!isMobileMode && <Footer />}
        </>
    );
}

export default AboutUs;
