import {createBrowserRouter } from "react-router-dom";
import { Home, Contactus, Faq, Privacypolicy, TermsAndConditions, UserPolicy, AboutUs } from "../pages";
import { HOME, CONTACTUS, FAQ, PRIVACYPOLICY, TAC, USERPOLICY, ABOUTUS } from "./keys";

const router=createBrowserRouter([
    {
        path:HOME,
        element:<Home/>,
    },
    {
        path:CONTACTUS,
        element:<Contactus/>,
    },
    {
        path:FAQ,
        element:<Faq/>,
    },
    {
        path:PRIVACYPOLICY,
        element:<Privacypolicy/>,
    },
    {
        path:TAC,
        element:<TermsAndConditions/>,
    },
    {
        path:USERPOLICY,
        element:<UserPolicy/>,
    },
    {
        path:ABOUTUS,
        element:<AboutUs/>,
    },
])
export default router;