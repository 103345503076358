import React, { useState, useEffect, useRef } from 'react';
import './Privacypolicy.css';
import { Footer, Header } from '../../components';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const sections = [
    { id: 'introduction', title: 'Introduction' },
    { id: 'information-we-collect', title: 'Information We Collect' },
    { id: 'how-we-use-your-information', title: 'How We Use Your Information' },
    { id: 'how-we-share-your-information', title: 'How We Share Your Information' },
    { id: 'international-data-transfers', title: 'International Data Transfers' },
    { id: 'data-security', title: 'Data Security' },
    { id: 'your-choices', title: 'Your Choices' },
    { id: 'childrens-privacy', title: 'Children\'s Privacy' },
    { id: 'changes-to-this-privacy-policy', title: 'Changes to This Privacy Policy' },
    { id: 'contact-us', title: 'Contact Us' },
];

const Privacypolicy = () => {
    const [activeSection, setActiveSection] = useState(sections[0].id);
    const sectionRefs = useRef([]);

    useEffect(() => {
        const observerOptions = {
            threshold: 0.1,
        };

        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setActiveSection(entry.target.id);
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        sectionRefs.current.forEach((section) => {
            if (section) observer.observe(section);
        });

        return () => {
            if (sectionRefs.current) {
                sectionRefs.current.forEach((section) => {
                    if (section) observer.unobserve(section);
                });
            }
        };
    }, []);

    const handleClick = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
            setActiveSection(id);
        }
    };

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isMobileMode = searchParams.get('mode') === 'mobile';

    return (
        <div className="privacy-policy">
            {!isMobileMode && <Header />}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Privacy Policy | nSepia</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <div className="main-container">
                <div className="sidebar-container open">
                    <nav className="sidebar">
                        <ul>
                            {sections.map((section) => (
                                <li
                                    key={section.id}
                                    className={`nav-item ${activeSection === section.id ? 'active' : ''}`}
                                    onClick={() => handleClick(section.id)}
                                >
                                    {section.title}
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
                <main className="content">
                    <h1>Privacy Policy</h1>
                    <p>Effective Date: 1st July 2024</p>
                    {sections.map((section, index) => (
                        <section
                            key={section.id}
                            id={section.id}
                            className={`section ${activeSection === section.id ? 'active-section' : ''}`}
                            ref={(el) => (sectionRefs.current[index] = el)}
                        >
                            {section.id === 'introduction' && (
                                <>
                                    <h2>1. Introduction</h2>
                                    <p>nSepia ("we," "us," "our," or the "Company") is committed to protecting the privacy of individuals who use our mobile application (the "App"). This Privacy Policy ("Policy") outlines our practices regarding the collection, use, disclosure, and protection of your personal information when you use the App.</p>
                                    <p>By using the App, you consent to the practices described in this Policy. If you do not agree with our practices, please do not use the App.</p>
                                </>
                            )}
                            {section.id === 'information-we-collect' && (
                                <>
                                    <h2>2. Information We Collect</h2>
                                    <h3>Information You Provide:</h3>
                                    <p><strong>Account Information:</strong> If you create an account, we may collect your email address, username, and password.</p>
                                    <p><strong>Questionnaires Responses:</strong> We collect your responses to relevant questionnaires like WEMWBS, PHQ-9, GAD-7, PWB, K10, MDQ, PDSS, and PSWQ to assess your anxiety levels.</p>
                                    <p><strong>Optional Information:</strong> You may choose to provide additional information, such as demographic data or feedback, to help us improve the App.</p>
                                    <h3>Information We Collect Automatically:</h3>
                                    <p><strong>Device Information:</strong> We may collect information about the device you use to access the App, including device type, operating system, unique device identifiers, and mobile network information.</p>
                                    <p><strong>Usage Data:</strong> We collect information about your interactions with the App, including the features you use, the pages you visit, and the time and date of your activities.</p>
                                    <p><strong>Location Data:</strong> With your consent, we may collect your precise location data to provide location-based services or personalized content. You can manage your location preferences in the App settings.</p>
                                    <p><strong>Log Data:</strong> We may collect server log information, including your IP address, browser type, referring/exit pages, and date/time stamps.</p>
                                    <h3>Information We Collect:</h3>
                                    <p><strong>Facial Landmark Data:</strong> We collect and process anonymized facial landmark data to authenticate your identity and ensure the security of your profile and assessment results. This data is not linked to any personally identifiable information.</p>
                                    <p><strong>Video and Audio Recordings:</strong> We record video and audio data during your interactions with the App. This data is used to:</p>
                                    <ul>
                                        <li><strong>Enhance Assessment Quality:</strong> By analyzing facial expressions and vocal patterns, we can better understand your emotional state and provide more accurate feedback.</li>
                                        <li><strong>Improve Services:</strong> We use anonymized recordings to analyze and improve the App's features, functionality, and overall user experience.</li>
                                        <li><strong>Research (With Your Consent):</strong> With your explicit consent, anonymized video and audio recordings may be used for research purposes to advance our understanding of anxiety and mental health.</li>
                                    </ul>
                                </>
                            )}
                            {section.id === 'how-we-use-your-information' && (
                                <>
                                    <h2>3. How We Use Your Information</h2>
                                    <p>We use your information for the following purposes:</p>
                                    <ul>
                                        <li><strong>Provide and Improve the App:</strong> To provide, maintain, and improve the App's functionality, features, and user experience.</li>
                                        <li><strong>Personalization:</strong> To personalize your experience with the App, such as by tailoring content or providing location-based services (with your consent).</li>
                                        <li><strong>Authentication:</strong> To verify your identity and secure your account (if applicable).</li>
                                        <li><strong>Questionnaires Assessment:</strong> To analyze your responses to questionnaires like WEMWBS, PHQ-9, GAD-7, PWB, K10, MDQ, PDSS, and PSWQ to assess your anxiety levels, calculate your score, and provide personalized feedback and resources.</li>
                                        <li><strong>Communication:</strong> To communicate with you about the App, new features, updates, or promotional offers (if you have opted in). You can manage your communication preferences in the App settings.</li>
                                        <li><strong>Research and Analytics:</strong> To conduct research and analysis to better understand our users and improve the App. This may include aggregating and anonymizing data for statistical purposes.</li>
                                        <li><strong>Legal Compliance:</strong> To comply with applicable laws, regulations, legal processes, or enforceable governmental requests.</li>
                                    </ul>
                                </>
                            )}
                            {section.id === 'how-we-share-your-information' && (
                                <>
                                    <h2>4. How We Share Your Information</h2>
                                    <p>We may share your information with the following parties:</p>
                                    <ul>
                                        <li><strong>Service Providers:</strong> We may engage trusted third-party service providers to perform functions and provide services to us, such as hosting and maintenance, data analysis, marketing, and customer service. These providers are contractually obligated to safeguard your information and are prohibited from using it for any other purpose.</li>
                                        <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, reorganization, or sale of all or a portion of our assets, your information may be transferred as part of the transaction. We will notify you via email or a prominent notice in the App of any such change in ownership or control of your personal information.</li>
                                        <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in the good faith belief that such action is necessary to comply with legal obligations, protect and defend our rights or property, or investigate potential wrongdoing.</li>
                                        <li><strong>Aggregated Data:</strong> We may share aggregated or de-identified information that cannot reasonably be used to identify you with third parties for research, marketing, or other purposes.</li>
                                    </ul>
                                </>
                            )}
                            {section.id === 'international-data-transfers' && (
                                <>
                                    <h2>5. International Data Transfers</h2>
                                    <p>We may transfer your information to servers located outside of your country of residence, including to the United States. By using the App, you consent to the transfer of your information to these countries, which may have different data protection laws than your country of residence.</p>
                                </>
                            )}
                            {section.id === 'data-security' && (
                                <>
                                    <h2>6. Data Security</h2>
                                    <p>We implement reasonable security measures designed to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the Internet or electronic storage is 100% secure. Therefore, we cannot guarantee absolute security.</p>
                                </>
                            )}
                            {section.id === 'your-choices' && (
                                <>
                                    <h2>7. Your Choices</h2>
                                    <p><strong>Account Information:</strong> You can access, update, or delete your account information by logging into your account settings.</p>
                                    <p><strong>Facial Recognition:</strong> You can disable the face recognition feature at any time.</p>
                                    <p><strong>Location Data:</strong> You can manage your location preferences in the App settings.</p>
                                    <p><strong>Communications:</strong> You can opt-out of receiving marketing communications by following the unsubscribe instructions provided in our emails or by adjusting your notification preferences in the App settings.</p>
                                </>
                            )}
                            {section.id === 'childrens-privacy' && (
                                <>
                                    <h2>8. Children's Privacy</h2>
                                    <p>The App is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13 without parental consent, we will take steps to remove such information from our servers.</p>
                                </>
                            )}
                            {section.id === 'changes-to-this-privacy-policy' && (
                                <>
                                    <h2>9. Changes to This Privacy Policy</h2>
                                    <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this Policy periodically.</p>
                                </>
                            )}
                            {section.id === 'contact-us' && (
                                <>
                                    <h2>10. Contact Us</h2>
                                    <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at <a href='mailto:support@nsepia.ai'>support@nsepia.ai</a>.</p>
                                </>
                            )}
                        </section>
                    ))}
                </main>
            </div>
            {!isMobileMode && <Footer />}
             
        </div>
    );
};

export default Privacypolicy;
